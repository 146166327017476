import { removeDuplicates, selectStyle } from "../utils.js";
import { Button } from "@mui/material";

export default function SelectLocal({ templates, setTemplates }) {
  const handleTemplate = (e) => {
    const addFiles = Object.values(e.target.files);
    const allFiles = [...templates, ...addFiles];
    setTemplates(removeDuplicates(allFiles));
  };
  return (
    <Button
      disableElevation
      variant="outlined"
      color="primary"
      component="label"
      sx={selectStyle}
    >
      {"Browse Templates"}
      <input
        type="file"
        multiple
        accept=".csv, .xlsx, .doc, .docx, .vsdx, .pptx, .txt, .cfg, .config, .json, .yaml, .vsdm"
        hidden
        onChange={handleTemplate}
        onClick={(event) => (event.target.value = null)}
      />
    </Button>
  );
}
