import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Space,
  Tabs,
  Tag,
  Typography,
  Divider,
} from "antd";

import React, { useState } from "react";
import { getData } from "./fortinetCalls.tsx/get";
import { ReloadOutlined } from "@ant-design/icons";
import TabPage from "./TabPage";
import { BorderedContent } from "../Common/Components/BorderedContent";
import ExportToExcel from "../Common/Components/Exports/ExportToExcel";

const { Text } = Typography;

const FortinetConnector = () => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [apiKey, setApiKey] = useState<string | undefined>(undefined);

  const reset = () => {
    setData([]);
    setApiKey(undefined);
  };

  const [form] = Form.useForm<{ apiKey: string }>();

  const apiKeyInput = Form.useWatch("apiKey", form);

  const getUi = async (api_key: string) => {
    setLoading(true);
    setApiKey(api_key);
    // Call the API with the API key
    const response = await getData("get_ui", { api_key });
    response.data && setData(response.data);
    response.error && setError(response.error);
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    getUi(values.apiKey);
  };

  const payloads = {};
  data.forEach(({ name, data }) => {
    payloads[name] = data;
  });
  console.log(payloads);

  const operations = (
    <>
      <ExportToExcel payloads={payloads} />
      <Divider type="vertical" />
      <Button
        size="small"
        icon={<ReloadOutlined />}
        onClick={() => apiKey && getUi(apiKey)}
      >
        Reload
      </Button>
    </>
  );

  return (
    <Flex vertical gap="middle" style={{ width: "100%" }}>
      <h1 className="colorIndigo" style={{ marginBottom: 16 }}>
        Fortinet Connector
      </h1>
      {!data.length ? (
        <Form
          form={form}
          name="connect"
          onFinish={onFinish}
          autoComplete="off"
          layout={"inline"}
        >
          <Form.Item name="apiKey">
            <Input.Password
              placeholder="Enter your Palo Alto API Key"
              style={{ width: 400 }}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!apiKeyInput}
              loading={loading}
            >
              {loading ? "Connecting" : "Connect"}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Card
          title={
            <Space>
              <>{"FortiManager Integration"}</>
              {/* <Tag bordered={false}>{"tenantId"}</Tag> */}
            </Space>
          }
          extra={
            apiKey && (
              <Flex align="flex-end" gap="middle">
                <small
                  style={{ marginLeft: 8 }}
                >{`Connected with token ***** ${apiKey?.slice(
                  -4,
                  apiKey.length
                )} `}</small>
                <Button size="small" type="text" onClick={() => reset()}>
                  <Text type="danger">
                    <small>{"Disconnect"}</small>
                  </Text>
                </Button>
              </Flex>
            )
          }
        >
          <Tabs
            tabBarExtraContent={operations}
            type="card"
            defaultActiveKey="1"
            items={data.map(({ name, columns, data }, index) => ({
              key: `${index + 1}`,
              label: name,
              children: (
                <BorderedContent>
                  <TabPage name={name} columns={columns} data={data} />
                </BorderedContent>
              ),
            }))}
            size="large"
          />
        </Card>
      )}
    </Flex>
  );
};

export default FortinetConnector;
