import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Menu, Typography, MenuItem, Button } from "@mui/material";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    transitionDuration: "0.3s",
    borderRadius: 5,
  },
  media: {
    height: 140,
    marginInline: "25%",
    objectFit: "contain",
    float: "center",
  },
  mediaContainer: {
    height: 140,
    backgroundColor: "#5514B4",
  },
  content: {
    height: 100,
    textAlign: "center",
  },
  button: {
    width: "80%",
    marginRight: "10%",
    marginLeft: "10%",
    marginBottom: "5%",
    justifyContent: "center",
    textAlign: "center",
  },
});

export default function CustomCard(props) {
  const classes = useStyles();
  const cookies = new Cookies();
  const accounts = cookies.get("accounts");
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const create_menu_item = (
    label,
    pathname,
    search = undefined,
    disabled = false
  ) => {
    return (
      <MenuItem
        sx={{
          width: "100%",
        }}
        key={label}
        disabled={disabled}
        onClick={() =>
          history.push({
            pathname: pathname,
            search: search,
          })
        }
      >
        {label}
      </MenuItem>
    );
  };

  const templates_options = [
    create_menu_item("Custom Design (Legacy)", "/design", "?journey=0"),
    create_menu_item("Multi-Template", "/templates"),
  ];

  const connector_options = [
    create_menu_item(
      "BFG Connector",
      "/bfg_connector",
      undefined,
      accounts?.length === 0
    ),
    create_menu_item(
      "Meraki Connector",
      "/meraki_connector",
      undefined,
      accounts?.length === 0
    ),
    create_menu_item(
      "Palo Alto Connector",
      "/palo_alto_connector",
      undefined,
      accounts?.length === 0
    ),
    create_menu_item(
      "Zscaler Connector",
      "/zscaler_connector",
      undefined,
      accounts?.length === 0
    ),
    create_menu_item(
      "Fortinet Connector",
      "/fortinet_connector",
      undefined,
      accounts?.length === 0
    ),
  ];

  const converter_options = [
    create_menu_item("Config Converter", "/configs_converter"),
    create_menu_item("CnGo Converter", "/cngo_converter"),
    create_menu_item("Ionix Converter", "/ionix_converter"),
  ];

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5">
          <strong>{props.title}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {props.description}
        </Typography>
      </CardContent>
      <CardActions>
        <div className={classes.button}>
          <div>
            <Button
              disableElevation
              sx={{
                color: "white",
                backgroundColor: "#5514b4",
                "&:hover": {
                  backgroundColor: "#7642c3",
                },
                width: "100%",
              }}
              size="small"
              variant="contained"
              onClick={handleClick}
            >
              {props.label}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              PaperProps={{
                style: {
                  width: "200px",
                },
              }}
            >
              {props.journey === "templates" && templates_options}
              {props.journey === "connectors" && connector_options}
              {props.journey === "conversion" && converter_options}
            </Menu>
          </div>
        </div>
      </CardActions>
    </Card>
  );
}
