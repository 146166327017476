// dependencies
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ProtectedRoute } from "../components/Authentication/ProtectedRoute";

// title component
import useDocumentTitle from "../components/Title/useDocumentTitle.js";

// pages
import JourneyPage from "./../pages/JourneyPage/JourneyPage";
import UserFeedbackPage from "./../pages/UserFeedbackPage/UserFeedbackPage";
import StatsPage from "./../pages/StatsPage/StatsPage";
import TemplatesPage from "./../pages/TemplatesPage/TemplatesPage";
import HelpPage from "./../pages/HelpPage/HelpPage";
import LoginPage from "./../pages/LoginPage/LoginPage";
import AdminMaster from "./../components/AdminPageViews/AdminMaster";
import LibraryManagePage from "./../pages/LibraryManagePage/LibraryManagePage";
import MapperManagePage from "./../pages/MapperManagePage/MapperManagePage";
import RFStatsPage from "./../pages/RFStatsPage/RFStatsPage";
import WAPConfigPage from "./../pages/WAPConfigPage/WAPConfigPage.js";
import WLCConfigPage from "./../pages/WLCConfigPage/WLCConfigPage.js";
import ConfigConverterPage from "./../pages/ConfigConverterPage/ConfigConverterPage.js";
import AccessRequestPage from "../pages/AccessRequestPage/AccessRequestPage";
import TertiaryManagePage from "../pages/TertiaryManagePage/TertiaryManagePage";
import DataMappingPage from "../pages/DataMappingPage/DataMappingPage.js";
import JourneyConsolidatorPage from "../pages/JourneyConsolidator/JourneyConsolidator";
import WizardPage from "../pages/WizardPage/WizardPage.js";
import WizardView from "../pages/WizardPage/WizardItemPage.js";
import CreateMapper from "../pages/MapperManagePage/MapperView";
import MerakiRollOutPage from "../pages/MerakiRollOutPage/MerakiRollOutPage";
import WizardstoreManagePage from "../pages/WizardstoreManagePage/WizardstoreManagePage";
import GuiTemplateManageView from "../pages/WizardstoreManagePage/GuiTemplateManageView";

// Custom views
// import ManualConfigView from "../pages/ConfigConverterPage/ConfigConverterViews/ManualConfigView";
// import MultipleConfigView from "../pages/ConfigConverterPage/ConfigConverterViews/MultipleConfigView";

// New Converters Pages
import CngoPage from "../pages/ConfigConverterPage/new_pages/CngoPage";
import ConfigPage from "../pages/ConfigConverterPage/new_pages/ConfigPage";
import IonixPage from "../pages/ConfigConverterPage/new_pages/IonixPage.js";

// Modern Start
import ModernAppPage from "../modern/App";
import ZscalerConnectorPage from "../modern/pages/Connectors/ZscalerConnector";
import MerakiConnectorPage from "../modern/pages/Connectors/MerakiConnector";
import MerakiScriptsPage from "../modern/pages/MerakiScripts";
import PaloAltoConnectorPage from "../modern/pages/Connectors/PaloAltoConnector";
import StatisticsModernPage from "../modern/pages/Statistics";
import TemplatingPage from "../modern/pages/Templating/TemplateEngine";
import BFGConnectorPage from "../modern/pages/Connectors/BFGConnector";
import FortinetConnectorPage from "../modern/pages/Connectors/FortinetConnector";
// Modern Finish

import Cookies from "universal-cookie";
// css
import "./App.css";

function App() {
  const cookies = new Cookies();
  // fetching api token from cookies
  const selectedAccessToken = cookies.get("access_token");

  // Adding title to page components
  function Home() {
    return <JourneyPage access_token={selectedAccessToken} />;
  }

  function Login() {
    useDocumentTitle("Login");
    return <LoginPage />;
  }

  function RFStats() {
    useDocumentTitle("RF Stats");
    return <RFStatsPage access_token={selectedAccessToken} />;
  }

  function WAPConfig() {
    useDocumentTitle("WAP Config");
    return <WAPConfigPage access_token={selectedAccessToken} />;
  }

  function WLCConfig() {
    useDocumentTitle("WLC Config");
    return <WLCConfigPage access_token={selectedAccessToken} />;
  }

  function ConfigConverter() {
    useDocumentTitle("Config Converter");
    return <ConfigConverterPage access_token={selectedAccessToken} />;
  }

  function UserFeedback() {
    useDocumentTitle("User Feedback");
    return <UserFeedbackPage access_token={selectedAccessToken} />;
  }

  function Stats() {
    useDocumentTitle("Statistics");
    return <StatsPage access_token={selectedAccessToken} />;
  }

  function Templates() {
    useDocumentTitle("Templates");
    return <TemplatesPage access_token={selectedAccessToken} />;
  }

  function Help() {
    useDocumentTitle("Help");
    return <HelpPage access_token={selectedAccessToken} />;
  }

  function Admin() {
    useDocumentTitle("Admin");
    return <AdminMaster access_token={selectedAccessToken} />;
  }
  function Access() {
    useDocumentTitle("Access Request");
    return <AccessRequestPage access_token={selectedAccessToken} />;
  }
  function LibraryManage() {
    useDocumentTitle("Library Manager");
    return <LibraryManagePage access_token={selectedAccessToken} />;
  }
  function WizardstoreManage() {
    useDocumentTitle("Wizardstore Manager");
    return <WizardstoreManagePage access_token={selectedAccessToken} />;
  }
  function TertiaryManage() {
    useDocumentTitle("Tertiary Manager");
    return <TertiaryManagePage access_token={selectedAccessToken} />;
  }
  function MapperManage() {
    useDocumentTitle("Mapper Manager");
    return <MapperManagePage access_token={selectedAccessToken} />;
  }
  function MapperEditor() {
    useDocumentTitle("Mapper Editor");
    return <CreateMapper access_token={selectedAccessToken} />;
  }
  function JourneyConsolidator() {
    useDocumentTitle("ngDS Design");
    return <JourneyConsolidatorPage access_token={selectedAccessToken} />;
  }
  function DataMappingManage() {
    useDocumentTitle("Data Mapping");
    return <DataMappingPage access_token={selectedAccessToken} />;
  }

  function WizardManage() {
    useDocumentTitle("Automation Wizard");
    return <WizardPage access_token={selectedAccessToken} />;
  }
  function WizardViewManage() {
    useDocumentTitle("Automation Wizard");
    return <WizardView access_token={selectedAccessToken} />;
  }

  function GuiTemplateManageFunc() {
    useDocumentTitle("GuiTemplate Manager");
    return <GuiTemplateManageView access_token={selectedAccessToken} />;
  }

  function MerakiRolloutManage() {
    useDocumentTitle("Meraki Rollout");
    return <MerakiRollOutPage access_token={selectedAccessToken} />;
  }

  function Cngo() {
    useDocumentTitle("CnGo Converter");
    return <CngoPage access_token={selectedAccessToken} />;
  }

  function Ionix() {
    useDocumentTitle("Ionix Converter");
    return <IonixPage access_token={selectedAccessToken} />;
  }

  function Config() {
    useDocumentTitle("Config Converter");
    return <ConfigPage access_token={selectedAccessToken} />;
  }

  function ZscalerConnector() {
    useDocumentTitle("Zscaler Connector");
    return (
      <ModernAppPage>
        <ZscalerConnectorPage />
      </ModernAppPage>
    );
  }

  function MerakiConnector() {
    useDocumentTitle("Meraki Connector");
    return (
      <ModernAppPage>
        <MerakiConnectorPage />
      </ModernAppPage>
    );
  }

  function MerakiScripts() {
    useDocumentTitle("Meraki Scripts");
    return (
      <ModernAppPage>
        <MerakiScriptsPage />
      </ModernAppPage>
    );
  }

  function PaloAltoConnector() {
    useDocumentTitle("Palo Alto Connector");
    return (
      <ModernAppPage>
        <PaloAltoConnectorPage />
      </ModernAppPage>
    );
  }

  function BFGConnector() {
    useDocumentTitle("BFG Connector");
    return (
      <ModernAppPage>
        <BFGConnectorPage />
      </ModernAppPage>
    );
  }

  function FortinetConnector() {
    useDocumentTitle("Fortinet Connector");
    return (
      <ModernAppPage>
        <FortinetConnectorPage />
      </ModernAppPage>
    );
  }

  function StatisticsNew() {
    useDocumentTitle("Stats Dashboard");
    return (
      <ModernAppPage>
        <StatisticsModernPage />
      </ModernAppPage>
    );
  }

  function Templating() {
    useDocumentTitle("Templating");
    return (
      <ModernAppPage>
        <TemplatingPage />
      </ModernAppPage>
    );
  }

  return (
    <Router>
      <Switch>
        {/* Titled routes */}
        {/* <Route exact path="/" component={Home} /> */}
        <ProtectedRoute exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <ProtectedRoute
          exact
          path="/zscaler_connector"
          component={ZscalerConnector}
        />
        <ProtectedRoute
          exact
          path="/meraki_connector"
          component={MerakiConnector}
        />
        <ProtectedRoute
          exact
          path="/meraki_scripts"
          component={MerakiScripts}
        />
        <ProtectedRoute
          exact
          path="/palo_alto_connector"
          component={PaloAltoConnector}
        />
        <ProtectedRoute
          exact
          path="/fortinet_connector"
          component={FortinetConnector}
        />
        <ProtectedRoute exact path="/bfg_connector" component={BFGConnector} />
        <ProtectedRoute exact path="/rfstats" component={RFStats} />
        <ProtectedRoute
          exact
          path="/wap_config_generator"
          component={WAPConfig}
        />
        <ProtectedRoute
          exact
          path="/wlc_config_analyser"
          component={WLCConfig}
        />
        <ProtectedRoute
          exact
          path="/config_converter"
          component={ConfigConverter}
        />
        <ProtectedRoute exact path="/configs_converter" component={Config} />
        <ProtectedRoute exact path="/cngo_converter" component={Cngo} />
        <ProtectedRoute exact path="/ionix_converter" component={Ionix} />
        <ProtectedRoute exact path="/user_feedback" component={UserFeedback} />

        {/* Stats routes */}

        <ProtectedRoute exact path="/stats" component={Stats} />
        <ProtectedRoute exact path="/statistics" component={StatisticsNew} />

        <ProtectedRoute exact path="/templates" component={Templates} />
        <ProtectedRoute exact path="/templating" component={Templating} />
        <ProtectedRoute exact path="/help" component={Help} />
        <ProtectedRoute exact path="/admin" component={Admin} />
        <ProtectedRoute exact path="/access_request" component={Access} />
        <ProtectedRoute
          exact
          path="/library_manager"
          component={LibraryManage}
        />
        <ProtectedRoute
          exact
          path="/wizardstore_manager"
          component={WizardstoreManage}
        />
        <ProtectedRoute
          exact
          path="/gui_template_manager"
          component={GuiTemplateManageFunc}
        />
        <ProtectedRoute exact path="/design" component={JourneyConsolidator} />
        <ProtectedRoute
          exact
          path="/tertiary_manager"
          component={TertiaryManage}
        />
        <ProtectedRoute
          exact
          path="/data_mapping"
          component={DataMappingManage}
        />
        <ProtectedRoute exact path="/wizard" component={WizardManage} />
        <ProtectedRoute
          exact
          path="/wizard_view"
          component={WizardViewManage}
        />
        <ProtectedRoute exact path="/mapper_manager" component={MapperManage} />
        <ProtectedRoute exact path="/mapper_editor" component={MapperEditor} />
        <ProtectedRoute
          exact
          path="/meraki_rollout"
          component={MerakiRolloutManage}
        />

        {/* Redirects from old UI endpoints */}
        <Redirect from="/catalogue_designs" to="/design?journey=1" />
        <Redirect from="/catalogue" to="/design?journey=1" />
        <Redirect from="/own_designs" to="/design?journey=0" />
        <Redirect from="/custom" to="/design?journey=0" />
        <Redirect from="/wlan/templates" to="/design?journey=2" />
        <Redirect from="/wlan_design" to="/design?journey=2" />
        <Redirect from="/sdwan_design" to="/design?journey=3" />
        <Redirect from="/wan_cpe" to="/design?journey=4" />
        <Redirect from="/administration" to="/admin" />
        {/* Redirects broken urls to home page */}
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}

export default App;
