import { makeStyles } from "@material-ui/core";
import AccountSelector from "../../components/AccountSelector/AccountSelector";
// import MenuIcon from "@material-ui/icons/Menu";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import AdminIcon from "@material-ui/icons/AccountCircle";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import HelpIcon from "@material-ui/icons/ContactSupport";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import LibraryIcon from "@material-ui/icons/LibraryBooks";
import FeedbackIcon from "@material-ui/icons/Feedback";
import RequestIcon from "@material-ui/icons/AssignmentLate";
import StatsIcon from "@material-ui/icons/Equalizer";
import MenuItem from "@mui/material/MenuItem";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import ListItemIcon from "@mui/material/ListItemIcon";

import ContentCopy from "@mui/icons-material/ContentCopy";
import Cloud from "@mui/icons-material/Cloud";
import Home from "@mui/icons-material/Home";
import CopyAll from "@mui/icons-material/CopyAll";
import StorageIcon from "@mui/icons-material/Storage";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import HubIcon from "@mui/icons-material/Hub";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { useDispatch, useSelector } from "react-redux";
import { setStoreAccount } from "../../features/account";

import { useLocalStorage } from "../../modern/hooks/useLocalStorage";

import logo from "../../modern/assets/images/ngds_logo_white_font.png";
import bt from "../../assets/BT_Logo_White.png";

const MANIFEST_ID = "release_20240905A";

// function deleteAllCookies() {
//   const cookies = document.cookie.split(";");

//   for (let i = 0; i < cookies.length; i++) {
//     const cookie = cookies[i];
//     const eqPos = cookie.indexOf("=");
//     const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;
//     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
//   }
//   localStorage.clear();
// }

export default function Header() {
  const cookies = new Cookies();
  const scopes = cookies.get("scopes");
  const history = useHistory();
  const classes = useStyles();

  const currentAccount = JSON.parse(localStorage.getItem("currentAccount"));

  console.log({ currentAccount });

  // UseEffect for page reloading when file manifest is changed
  useEffect(() => {
    const id = localStorage.getItem("id"); // client manifest id
    const manifest = { id: MANIFEST_ID }; //  manifest object
    console.log("local id", id, "manifest.id", manifest.id);

    const reloadIfFilesChanged = (cnt = 0) => {
      try {
        if (cnt > 10) {
          return;
        }
        // Manifest has changed, fire reload and set new id
        // Call the function to delete all cookies
        localStorage.setItem("id", manifest.id);
        console.log("Reloading Page");
        window.location.replace(`${window.location.href}?${manifest.id}`);
      } catch (e) {
        console.error("Error in reloadIfFilesChanged:", e);
        setTimeout(() => reloadIfFilesChanged(cnt + 1, manifest), 1000);
      }
    };

    // Call the function to check for changes
    if (!id || id !== manifest.id) reloadIfFilesChanged();
  });

  const [show, setShow] = React.useState(false);

  const navigate = (path) => {
    history.push(path);
    setShow(false);
  };

  const toggleDrawerNew = (newOpen) => () => {
    setShow(newOpen);
  };

  const NgdsItem = ({ icon, title, path, disabled = false }) => {
    const IconComponent = icon;
    return (
      <MenuItem onClick={() => navigate(path)} disabled={disabled}>
        <ListItemIcon>
          {IconComponent && <IconComponent fontSize="small" />}
        </ListItemIcon>
        <ListItemText primary={title} />
      </MenuItem>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ bgcolor: "#5514b4", paddingRight: "8%", paddingLeft: "8%" }}
      >
        <Toolbar
          variant="dense"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              edge="start"
              color="inherit"
              onClick={toggleDrawerNew(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>

            <img
              style={{
                width: 48,
                marginBottom: 6,
              }}
              src={bt}
              alt="bt"
            />

            <img
              style={{
                width: 96,
                marginBottom: 6,
              }}
              src={logo}
              alt="ngDS"
            />
          </Box>
          <div style={{ float: "right", marginTop: "-10px" }}>
            <AccountSelector />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer open={show} onClose={toggleDrawerNew(false)}>
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <MenuList>
            <NgdsItem icon={Home} title={"Home"} path={"/"} />
            <Divider />
            <NgdsItem
              icon={ContentCopy}
              title={"Custom Design"}
              path={"/design?journey=0"}
            />
            <NgdsItem
              icon={CopyAll}
              title={"Multi-Templates"}
              path={"/templates"}
            />
            <Divider />
            <NgdsItem
              icon={StorageIcon}
              title={"BFG Connector"}
              path={"/bfg_connector"}
              disabled={currentAccount?.name === "None"}
            />
            <NgdsItem
              icon={Cloud}
              title={"Meraki Connector"}
              path={"/meraki_connector"}
              disabled={currentAccount?.name === "None"}
            />
            <NgdsItem
              icon={Cloud}
              title={"Palo Alto Connector"}
              path={"/palo_alto_connector"}
              disabled={currentAccount?.name === "None"}
            />
            <NgdsItem
              icon={Cloud}
              title={"Zscaler Connector"}
              path={"/zscaler_connector"}
              disabled={currentAccount?.name === "None"}
            />
            <NgdsItem
              icon={Cloud}
              title={"Fortinet Connector"}
              path={"/fortinet_connector"}
              disabled={currentAccount?.name === "None"}
            />
            <Divider />
            <NgdsItem
              icon={TextSnippetIcon}
              title={"Config Converter"}
              path={"/configs_converter"}
            />
            <NgdsItem
              icon={HubIcon}
              title={"CnGo Converter"}
              path={"/cngo_converter"}
            />
            <NgdsItem
              icon={AttachEmailIcon}
              title={"Ionix Converter"}
              path={"/ionix_converter"}
            />
            <Divider />
            <NgdsItem icon={HelpIcon} title={"Help"} path={"/help"} />
            <NgdsItem
              icon={RequestIcon}
              title={"Access Request"}
              path={"/access_request"}
            />
            <NgdsItem
              icon={FeedbackIcon}
              title={"Feedback"}
              path={"/user_feedback"}
            />

            {(scopes?.includes("admin") || scopes?.includes("reporter")) && [
              <Divider />,
              <NgdsItem
                icon={StatsIcon}
                title={"Stats Dashboard"}
                path={"/statistics"}
              />,
            ]}
            {scopes?.includes("admin") && [
              <Divider />,
              <NgdsItem icon={AdminIcon} title={"Admin"} path={"/admin"} />,
              <NgdsItem
                icon={LibraryIcon}
                title={"Library Management"}
                path={"/library_manager"}
              />,
            ]}
            <NgdsItem
              icon={StatsIcon}
              title={"Old Stats Reports (admin only)"}
              path={"/stats"}
            />
            <Divider />
            <NgdsItem icon={LogoutIcon} title={"Logout"} path={"/login"} />
          </MenuList>
        </Paper>
      </Drawer>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#5514B4",
    paddingRight: "10%",
    paddingLeft: "10%",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  aTag: { color: "inherit" },
  logo: {
    fontFamily: "BTRegCon",
    cursor: "pointer",
    paddingLeft: "1rem",
    fontSize: "1.5rem",
    color: "#FFFEFE",
    fontWeight: "300",
    textAlign: "left",
    display: "inline",
  },
  btlogo: {
    height: "72px",
    pointerEvents: "none",
    float: "left",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  logoContainer: {
    alignContent: "left",
    lineHeight: "75px",
    paddingLeft: "22px",
  },
  list: {
    width: 230,
  },
  fullList: {
    width: "auto",
  },
}));
