import axios from "axios";

interface ApiConnectorBody {
  account_id: number;
  connector: string;
  params: Record<string, any>;
  action: string;
}

export const callApiConnector = async (
  connector: string,
  action: string,
  params: Record<string, any>
) => {
  const account = localStorage.getItem("currentAccount") || undefined;
  const account_id = account ? +JSON.parse(account)?.id : 0;
  const userString = localStorage.getItem("user");
  const user = userString ? JSON.parse(userString) : null;

  const url = "/api/v1/api_connectors";
  const body: ApiConnectorBody = {
    account_id,
    connector,
    params,
    action,
  };

  console.log({ body });
  const response = {
    data: null,
    error: null,
  };

  await axios
    .post(url, body, {
      headers: { Authorization: "Bearer " + user.access_token },
    })
    .then((resp: any) => {
      console.log("Response:", resp.data);
      response.data = resp.data;
    })
    .catch((error: any) => {
      console.error("Error:", error);
      response.error = error;
    });

  return response;
};

// // Example usage
// const account = { id: "123" };
// const connector = "exampleConnector";
// const action = "exampleAction";
// const params = { key: "value" };

// callApiConnector(account, connector, params, action)
//   .then((data) => console.log("Success:", data))
//   .catch((error) => console.error("Failure:", error));
