import { Space } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import BaseTable from "../../../components/Tables/BaseTable";
import ShowRecordDetails from "../../../components/Tables/ShowRecordDetails";
import { ArrowsAltOutlined } from "@ant-design/icons";

interface RowAction {
  key: string;
  component: any;
  icon: React.JSX.Element;
}

export default function TabPage({ name, columns, data }) {
  const actionMenu: RowAction[] = [
    {
      key: "listDetails",
      component: ShowRecordDetails,
      icon: <ArrowsAltOutlined />,
    },
  ];

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", marginTop: 16 }}
    >
      {/* {contextHolder} */}
      <BaseTable
        // loading={loadingTenantData && !errorTenantData}
        tableName={name}
        dataSource={data}
        columns={columns}
        rowActions={actionMenu}
      />
    </Space>
  );
}
